import { FieldErrorMessage } from "../others"

const InputAuth = ({ 
    formikProps,
    type,
    text, 
    name, 
    placeholder
}) => {
    return (
        <div>
            <label
                htmlFor={name}
                className="block text-sm font-medium leading-6 text-gray-900"
            >
                {text}
            </label>
            <div className="mt-2">
                <input
                className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${
                    formikProps.touched[name] && formikProps.errors[name]
                    ? "border-red-500"
                    : "border-gray-200"
                } placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                type={type}
                placeholder={placeholder}
                value={formikProps.values[name]}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                name={name}
                />
                <FieldErrorMessage name={name} />
            </div>
        </div>
    )
}

export default InputAuth;