import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import AuthLayout from '../shared/components/AuthLayout';
import Layout from '../shared/components/Layout';
import PrivateRoute from '../shared/utils/routeTypes/PrivateRoute';
import PaymentRoute from '../shared/utils/routeTypes/PaymentRoute';
import VerifiedRoute from '../shared/utils/routeTypes/VerifiedRoute';
import AdminRoute from '../shared/utils/routeTypes/AdminRoute';

import App from '../../App';
import NotFound from '../pages/notFound';
import { Confirmation, ConfirmEmail, ForgotPassword, ResetPassword, OauthLogin, SignIn, SignUp } from '../pages/auth'
import { PaymentSuccess, Pricing } from '../pages/payment'
import { Profile } from '../pages/profile'
import { ChangeEmailVerification } from '../pages/profile/emailChange'
import { Dashboard } from '../pages/dashboard';
import Docs from '../pages/docs';
import { AllPostEditor, NewPostEditor, UpdatePostEditor } from '../pages/postEditor';
import FeaturesList from '../pages/features/FeaturesList'
import { PostOutline, PostH1Tag } from '../pages/features/Blog'
import { LexicalField } from '../pages/features/Writing'
import ErrorLogs from '../pages/serverLogs/error_logs';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="*" element={<NotFound />} />
      <Route path="" element={<AuthLayout />}>
        <Route index={true} path="/login" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/oauth-login" element={<OauthLogin />} />
        <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
        <Route path="/change-email-confirmation/:token" element={<ChangeEmailVerification/>}/>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword/>}/>
      </Route>
      <Route path="" element={<PrivateRoute />}>
        <Route path="" element={<AuthLayout />}>
          <Route path="/confirmation" element={<Confirmation />} />
        </Route>
        <Route path="" element={<VerifiedRoute />}>
          <Route path="" element={<Layout />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/pricing" element={<Pricing/>}/>
            <Route path="" element={<AdminRoute />}>
              <Route path="/error-logs" element={<ErrorLogs />} />
            </Route>
            <Route path="/payment/success" element={<PaymentSuccess/>}/>
            <Route path="" element={<PaymentRoute/>}>
              <Route path="/dashboard" element={<Dashboard/>}/>
              {/* <Route path="/profile" element={<Profile />} /> */}
              <Route path="/features" element={<FeaturesList/>} />
              <Route path="/blog-posts" element={<AllPostEditor/>}/>
              <Route path="/post-editor/new" element={<NewPostEditor/>}/>
              <Route path="/post-editor/:id" element={<UpdatePostEditor/>}/>
              <Route path="/docs" element={<Docs/>}/>
              <Route path="/feature/post-outline" element={<PostOutline/>}/>
              <Route path="/feature/post-h1-tag" element={<PostH1Tag/>}/>
              <Route path="/feature/lexical-field" element={<LexicalField/>}/>
            </Route>
          </Route>
        </Route>
      </Route>

    </Route>
  )
);
export default router;

