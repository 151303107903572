import ConstructionIcon from '@mui/icons-material/Construction';
import DrawIcon from '@mui/icons-material/Draw';
import BookIcon from '@mui/icons-material/Book';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CasinoIcon from '@mui/icons-material/Casino';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const sidnav = [

  {name: 'Dashboard',
  icon: <DashboardIcon style={{ color: 'white', fontSize: '20px' }} />,
  link_to: '/dashboard'
  },
  {
    name: 'Main Tools', // Parent menu
    icon: <ConstructionIcon style={{ color: 'white', fontSize: '20px' }} />, // Add the appropriate icon for Main Tools
    children: [
      {
       name:'All Tools',
       icon:<HomeRepairServiceIcon style={{ color: 'white', fontSize: '20px' }} />,
       link_to:'/features'
      },
      {
        name: 'Writing',
        icon: <DrawIcon style={{ color: 'white', fontSize: '20px' }}  />,
        link_to: '/features?type=writing',
      },
      {
        name: 'Blog',
        icon: <BookIcon style={{ color: 'white', fontSize: '20px' }} />,
        link_to: '/features?type=blog',
      },
      {
        name: 'Ecommerce',
        icon: <ShoppingCartIcon style={{ color: 'white', fontSize: '20px' }}/>,
        link_to: '/features?type=ecommerce',
      },
      {
        name:'SEO Strategies',
        icon:<CasinoIcon  style={{ color: 'white', fontSize: '20px' }}/>,
        link_to: '/features?type=strategy',
      },
      {
        name:'Content Ideas',
        icon:<LightbulbIcon  style={{ color: 'white', fontSize: '20px' }}/>,
        link_to: '/features?type=content-ideas',
      },
      {
        name:'Promotion',
        icon:<RecordVoiceOverIcon style={{ color: 'white', fontSize: '20px' }}/>,
        link_to: '/features?type=promotion',
      }
    ],
  },
  {
    name: 'Post Editor',
    icon: <HistoryEduIcon style={{ color: 'white', fontSize: '20px' }} />,
    link_to: '/blog-posts',
  },
  {
    name: 'Docs',
    icon: <ArticleRoundedIcon style={{ color: 'white', fontSize: '20px' }} />,
    link_to: '/docs',
  },
  {
    name: 'Error Logs',
    icon: <ErrorOutlineIcon style={{ color: 'white', fontSize: '20px' }} />,
    link_to: '/error-logs',
  }
];
export default sidnav;
