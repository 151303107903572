import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { AccountInitialValues, AccountSchema } from "./helper";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateProfileMutation } from "../../redux/slices/profileApiSlice";
import { toast } from "react-toastify";
import { setCredentials } from "../../redux/slices/authSlice";
import { InputProfile } from "../../shared/components/profile"

const Account = () => {
  const [update, {isLoading}] = useUpdateProfileMutation();
  const dispatch = useDispatch();
  const { userInfo, accessToken } = useSelector((state) => state?.auth);
  const { t } = useTranslation();

  return (
    <>
      <Formik
        initialValues={userInfo ? userInfo : AccountInitialValues}
        enableReinitialize={true}
        validationSchema={AccountSchema}
        onSubmit={async (values) => {
          try {
            const res = await update(values);

            if(res?.data?.message){
              const updatedUserInfo = {
                ...userInfo,
                first_name: values.first_name,
                last_name: values.last_name,
                username: values.username
              };

              dispatch(setCredentials({
                accessToken: accessToken,
                userInfo: updatedUserInfo
              }));
              toast.success("Updated Successfully");
            }
          
          } catch (error) {
            toast.error(error?.data?.detail || "Something went wrong");
          }
        }}
      >
        {(props) => (
            <div className="mx-auto h-[70vh] flex items-center justify-center bg-white shadow-default rounded-2xl">
            <form onSubmit={props.handleSubmit}>
              <div className="grid md:grid-cols-2 gap-x-11 gap-5">
                
                <InputProfile
                  formikProps={props}
                  type="text"
                  text={t("enter-first-name", "Entrez votre prénom")}
                  name="first_name"
                  placeholder={t("enter-first-name", "Entrez votre prénom")}
                />

                <InputProfile
                  formikProps={props}
                  type="text"
                  text={t("enter-last-name", "Entrez votre nom de famille")}
                  name="last_name"
                  placeholder={t("enter-last-name", "Entrez votre nom de famille")}
                />

                <InputProfile
                  formikProps={props}
                  type="text"
                  text={t("enter-username", "Entrez votre nom d'utilisateur")}
                  name="username"
                  placeholder={t("enter-username", "Entrez votre nom d'utilisateur")}
                />

                <div className="col-span-2">
                  <button className="px-5 w-full py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light">
                  {isLoading ? (
                      <ClipLoader size={25} color="white" />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
                
              </div>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default Account;
