import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Tooltip } from '@mui/material'
import { useSelector } from 'react-redux';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';

const ReferralPanel = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state?.auth.userInfo);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const copyReferralLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/signup?referral=${userInfo.unique_id}`)
      .then(() => {
        setTooltipOpen(true);
        setTimeout(() => {
          setTooltipOpen(false);
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className='rounded-lg shadow-default bg-white px-4 py-4 mt-3'>
      <p className='text-black text-xl font-bold'> {t("gain-3-articles-per-month", "Gagnez 3 articles/mois + offrir 3 articles/mois GRATUITEMENT !")}</p>
      <p className='block font-sans text-sm antialiased font-light leading-relaxed text-inherit mt-3'>{t("share-link-to-gain-3-articles-per-month", "Vous connaissez des personnes qui seraient intéressées par BotBlogR ? S'ils s'inscrivent directement avec votre propre lien, vous et le nouvel inscrit gagnez 3 articles supplémentaires par mois pendant 3 mois !")}</p>
      <p><a className='font-semibold mt-3 block' href="/affiliate-program">{t("learn-more-affiliate-program", "En savoir plus sur notre programme d'affiliation")}</a></p>
      <div>
        <div className='relative left-1/2 -translate-x-1/2'>
          <Tooltip
            title={t("link-copied", "Lien copié !")}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            placement="top"
          >
        <button onClick={copyReferralLink} className='bg-primary hover:bg-blue-200 font-light px-5 py-[6px] mt-3 text-white rounded'>
        <FileCopyIcon /> Copier mon lien !
        </button>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default ReferralPanel