import React from "react";
import pen from "../../../assets/images/pen.svg";
import { GVModalTemplate } from "./index";

const CompanyModal = ({ 
  open, 
  handleClose,
  formikProps,
  companyStyles
}) => {
 
  return (
    <GVModalTemplate
      open={open}
      handleClose={handleClose}
      formikProps={formikProps}
      generationVariablesList={companyStyles}
      fieldName="company_id"
      modalTitle="Entreprise / Site Web"
      modalIcon={pen}
      modalDescription="Provide more context about your website or business to personalize the generated content and tailor it to your target audience."
      noSelectionText="Aucune entreprise sélectionnée"
    />
  );
};

export default CompanyModal;
