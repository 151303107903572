import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from "react-redux";
import { ClipLoader } from 'react-spinners';

const PlansOptions = ({
    isPage,
    selectSubscription
}) => {
    const { t } = useTranslation();
    const userInfo = useSelector((state) => state?.auth).userInfo;
    const token = useSelector((state) => state?.auth?.accessToken);

    const [isYearly, setIsYearly] = useState(false);
    const [hoveredButton, setHoveredButton] = useState(null);

    const [standardLoading,setStandardLoading]=useState(false)
    const [premiumLoading,setPremiumLoading]=useState(false)
    const [ultimateLoading,setUltimateLoading]=useState(false)

    const STANDARD_PRICE_ID = process.env.REACT_APP_MONTHLY_STRIPE_STANDARD_ID;
    const ANNUAL_STANDARD_PRICE_ID = process.env.REACT_APP_ANNUAL_STRIPE_STANDARD_ID;

    const PREMIUM_PRICE_ID = process.env.REACT_APP_MONTHLY_STRIPE_PREMIUM_ID;
    const ANNUAL_PREMIUM_PRICE_ID = process.env.REACT_APP_ANNUAL_STRIPE_PREMIUM_ID;

    const ULTIMATE_PRICE_ID = process.env.REACT_APP_MONTHLY_STRIPE_ULTIMATE_ID;
    const ANNUAL_ULTIMATE_PRICE_ID = process.env.REACT_APP_ANNUAL_STRIPE_ULTIMATE_ID;

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
    
    // CHECKOUT SESSIONS FOR WHEN USER DOES NOT HAVE ANY PLAN
    const createCheckoutSession = async (priceId) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/payment/create-checkout-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                priceId: priceId
            })
        });

        const result = await response.json();
        return result;
    };

    const handleStandardCheckout = async () => {
    try {
        setStandardLoading(true)
        const data = await createCheckoutSession(isYearly ? ANNUAL_STANDARD_PRICE_ID : STANDARD_PRICE_ID);
        const stripe = await stripePromise;
        setStandardLoading(false)
        stripe.redirectToCheckout({
            sessionId: data.sessionId
        });
    } catch (error) {
        console.error('Error during premium checkout:', error);
    }
    };

    const handlePremiumCheckout = async () => {
    try {
        setPremiumLoading(true)
        const data = await createCheckoutSession(isYearly ? ANNUAL_PREMIUM_PRICE_ID : PREMIUM_PRICE_ID);
        const stripe = await stripePromise;
        setPremiumLoading(false)
        stripe.redirectToCheckout({
            sessionId: data.sessionId
        });
    } catch (error) {
        console.error('Error during basic checkout:', error);
    }
    };

    const handleUltimateCheckout = async () => {
    try {
        setUltimateLoading(true)
        const data = await createCheckoutSession(isYearly ? ANNUAL_ULTIMATE_PRICE_ID : ULTIMATE_PRICE_ID);
        const stripe = await stripePromise;
        setUltimateLoading(false)
        stripe.redirectToCheckout({
            sessionId: data.sessionId
        });
    } catch (error) {
        console.error('Error during basic checkout:', error);
    }
    };

    const tiers = [
    {
        title: 'Standard',
        price: (isYearly ? "349.00" : "34.90"),
        description: "Pour les rédacteurs débutants et occasionnels",
        features_list: [
            "+ de 40 outils de création de contenu pour son site web",
            "50k mots / mois à générer", 
            "Support Client VIP", 
            "Accès au groupe privé BotBlogR"
        ],
        buttonText: (userInfo.payment_status === null ? t("choose-subscription", "Choisir cet abonnement") : ((userInfo.plan === "Standard" && ((isYearly && userInfo.plan_period === "annual") || (!isYearly && userInfo.plan_period === "monthly"))) ? t("current-plan", "Abonnement actuel"): t("change-plan", "Changer d'abonnement"))),
        buttonVariant: 'contained',
        loading: standardLoading,
        disabled:((userInfo.plan === "Standard" && ((isYearly && userInfo.plan_period === "annual") || (!isYearly && userInfo.plan_period === "monthly"))) ? true : standardLoading),
        onClick: () => (userInfo.plan === null ? handleStandardCheckout() : (isYearly ? selectSubscription(ANNUAL_STANDARD_PRICE_ID) : selectSubscription(STANDARD_PRICE_ID))),
    },
    {
        title: 'Premium',
        subheader: 'Le plus populaire',
        price: (isYearly ? "499.00" : "49.90"),
        description: "Pour les rédacteurs confirmés et réguliers",
        features_list: [
            "Éditeur d'articles de blog automatisé", 
            "Outil ultime d'analyse de page SEO"
        ],
        buttonText: (userInfo.payment_status === null ? t("choose-subscription") : ((userInfo.plan === "Premium" && ((isYearly && userInfo.plan_period === "annual") || (!isYearly && userInfo.plan_period === "monthly"))) ? t("current-plan"): (t("change-plan")))),
        buttonVariant: 'contained',
        loading: premiumLoading,
        disabled:((userInfo.plan === "Premium" && ((isYearly && userInfo.plan_period === "annual") || (!isYearly && userInfo.plan_period === "monthly"))) ? true : premiumLoading),
        onClick: () => (userInfo.plan === null ? handlePremiumCheckout() : (isYearly ? selectSubscription(ANNUAL_PREMIUM_PRICE_ID) : selectSubscription(PREMIUM_PRICE_ID))),
    },
    {
        title: 'Ultimate',
        price: (isYearly ? "999.00" : "99.90"),
        description: "Pour les rédacteurs professionnels et webmaster",
        features_list: [
            "Outil de rédaction depuis un template", 
            "Option d'auto-publication sur Wordpress, Wix, Shopify...",
        ],
        buttonText: (userInfo.payment_status === null ? t("choose-subscription") : ((userInfo.plan === "Ultimate" && ((isYearly && userInfo.plan_period === "annual") || (!isYearly && userInfo.plan_period === "monthly"))) ? t("current-plan"): t("change-plan"))),
        buttonVariant: 'contained',
        loading: ultimateLoading,
        disabled:((userInfo.plan === "Ultimate" && ((isYearly && userInfo.plan_period === "annual") || (!isYearly && userInfo.plan_period === "monthly"))) ? true : ultimateLoading),
        onClick: () => (userInfo.plan === null ? handleUltimateCheckout() : (isYearly ? selectSubscription(ANNUAL_ULTIMATE_PRICE_ID) : selectSubscription(ULTIMATE_PRICE_ID))),
    },
    ];

    return (
        <div className={`${isPage ? "mt-8" : "mt-6"}`}>
            
            <div className="flex flex-col justify-center items-center">

                {userInfo.payment_status === null
                && <p className="text-center mb-4">{t("choose-a-subscription-to-start", "Choisissez un abonnement pour commencer")}</p>}

                <label className={`flex items-center cursor-pointer relative`}>
                    <div className={`bg-blue-50 rounded-full flex justify-between p-1 gap-1 sm:w-[30rem] max-sm:w-[25rem] relative`}>
                        <button
                            onClick={() => setIsYearly(false)}
                            onMouseEnter={() => setHoveredButton('monthly')}
                            onMouseLeave={() => setHoveredButton(null)}
                            className={`w-1/2 text-center py-2 px-3 rounded-full transition-all duration-300 ${
                                !isYearly
                                    ? 'bg-white text-black'
                                    : hoveredButton === 'monthly'
                                    ? 'text-white bg-white/40'
                                    : 'text-white'
                            }`}
                        >
                            Paiement mensuel
                        </button>
                        <button
                            onClick={() => setIsYearly(true)}
                            onMouseEnter={() => setHoveredButton('yearly')}
                            onMouseLeave={() => setHoveredButton(null)}
                            className={`w-1/2 text-center py-2 px-3 rounded-full transition-all duration-300 ${
                                isYearly
                                    ? 'bg-white text-black'
                                    : hoveredButton === 'yearly'
                                    ? 'text-white bg-white/40'
                                    : 'text-white'
                            }`}
                        >
                            <div>Paiement annuel</div>
                            <div className="text-xs text-gray-400">2 mois gratuits</div>
                        </button>
                    </div>
                </label>


            </div>


            <div className="mt-8 container space-y-12 lg:space-y-0 gap-y-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
                {tiers.map((tier, tierIndex) => (
                    <div key={`${tier.name}-${tierIndex}`} className="relative md:p-8 p-6 border border-gray-200 rounded-2xl shadow-sm flex flex-col">
                        <div className="flex-1">

                            {tier.subheader ? 
                                <p className={`absolute top-0 left-1/2 py-1.5 px-4 uppercase text-xs font-semibold bg-blue-50 text-white rounded-full tracking-wide transform -translate-x-1/2 -translate-y-1/2`}>
                                    {tier.subheader}
                                </p>
                            : null}


                            <h2 className={`text-xl font-semibold`}>{tier.title}</h2>

                            <p className="mt-4 flex items-baseline ">
                                <span className={`text-4xl font-extrabold tracking-tight`}>{tier.price}€</span><span className={`text-xl ml-1 font-semibold`}>/{isYearly ? t("year", "an") : t("month", "mois")}</span>
                            </p>

                            <p className={`text-md mt-4 font-semibold`}>{tier?.description}</p>
                            
                            <button
                                onClick={tier.onClick}
                                className={`${tier.disabled && "bg-blue-50/50" || "bg-blue-50 hover:bg-blue-100"} ${tier.disabled && "text-blue-50" || "text-white"} mt-4 block w-full py-3 px-6 border border-transparent rounded-md text-center`}
                                to="/auth/login"
                                disabled={tier.disabled}
                            >
                                {!tier.loading ? tier?.buttonText : <ClipLoader size={25} color="white"/>}
                            </button>
                            
                            <p className={`text-sm mt-6`}>{tier.title === "Standard" ? "L'abonnement comprend :" : tier.title === "Premium" ? "Tout dans le plan Standard, plus :" : "Tout dans le plan Premium, plus :"}</p>
                            <ul role="list" className="mt-4 space-y-4">
                                {tier.features_list.map((line, lineIndex) => (
                                    <li key={`${tier.name}-line-${lineIndex}`} className={`text-xs flex items-start`}>
                                    <svg
                                        className="w-4 h-4 mr-2 text-green-500 flex-shrink-0"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                    {line}
                                    </li>
                                ))}
                                </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PlansOptions;