import React, { useEffect, useState } from "react";

const NewPostEditor = () => {
  return (
    <>
      <p>New post editor</p>
    </>
  );
};

export default NewPostEditor;
