import { apiSlice } from "./apiSlice";
import { ENDPOINTS } from "../../shared/constants/urls";

export const postEditorBlogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPostEditorBlog: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/blog-post/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateAll: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.POST_EDITOR_BLOG}generate-all/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateGenerateAll: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.POST_EDITOR_BLOG}update-generate-all/${data.id}/`,
        method: "PUT",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateFromInstruction: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.POST_EDITOR_BLOG}generate-from-instruction/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    getAllPostEditorBlog: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/blog-post/`,
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getPostEditorBlog: builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/blog-post/${id}/`,
        method: "GET",
      }),
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    updateSinglePostEditorBlog:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/blog-post/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSinglePostEditorBlog:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/blog-post/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSelectedPostEditorBlog: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/blog-post/selection/`,
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',  // Ensure this header is present
        },
        body: JSON.stringify(data)  // Make sure the body is correctly serialized as JSON
      }),
      extraOptions: { requiresAuth: true },
    }),
  }),
});

export const {
    useCreatePostEditorBlogMutation,
    useDeleteSinglePostEditorBlogMutation,
    useGenerateAllMutation,
    useGenerateFromInstructionMutation,
    useGetAllPostEditorBlogQuery,
    useGetPostEditorBlogMutation,
    useUpdateSinglePostEditorBlogMutation,
    useUpdateGenerateAllMutation,
    useDeleteSelectedPostEditorBlogMutation
  } = postEditorBlogApiSlice;
