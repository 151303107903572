import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
  useGetAllPostEditorBlogQuery,
  useDeleteSinglePostEditorBlogMutation,
  useCreatePostEditorBlogMutation,
  useDeleteSelectedPostEditorBlogMutation
} from "../../redux/slices/postEditorBlogSlice";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { DefaultPostEditor } from './helper';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { H1Header } from "../../shared/components/others";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteIcon } from "../../shared/components/others/Icons";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const AllPostEditor = () => {
  const { t } = useTranslation();

  const { data: blogPostList, isLoading, isError,refetch } = useGetAllPostEditorBlogQuery();
  const [deletePostEditor, { isLoading: deleteLoading }] = useDeleteSinglePostEditorBlogMutation();
  const [deleteSelectedPostEditor, { isLoading: deleteSelectedLoading }] = useDeleteSelectedPostEditorBlogMutation();
  const [createPostEditor, {isLoading: createLoading}] = useCreatePostEditorBlogMutation();

  const navigate = useNavigate()
  const userInfo = useSelector((state) => state?.auth).userInfo;
  const isReadOnly = userInfo.payment_status === "pending";

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deletionType, setDeletionType] = useState("single");
  const [selectedPosts, setSelectedPosts] = useState(new Set());
  const [bulkAction, setBulkAction] = useState('');

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    const formattedDate = date.toLocaleDateString('fr-FR');
    const formattedTime = date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
  
    return `${formattedDate} à ${formattedTime}`;
  }

  const sortedBlogPosts = blogPostList?.data
  ? [...blogPostList.data].sort((a, b) => {
      return new Date(b.last_update_date).getTime() - new Date(a.last_update_date).getTime(); }) : [];

  const isAllSelected = sortedBlogPosts && sortedBlogPosts.length > 0 && sortedBlogPosts.every(post => selectedPosts.has(post.id));

  const handleDeleteModal = (deleteType, open=true, id=null) => {
    setDeletionType(deleteType);
    setSelectedId(id)
    if (open) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  const handleDeleteSelectedBlogPosts = async () => {
    try {
      const res = await deleteSelectedPostEditor(Array.from(selectedPosts)).unwrap();
      setOpen(false);
      refetch();
      toast.success("Data Delete Successfully");
    } catch (error) {
      toast.error("Error Occur while deleting");
    }
  }

  const handleDeleteBlogPost = async(id, modal=false)=>{
    try {
      const res = await deletePostEditor({ id }).unwrap();
      if (modal) {
        setOpen(false);
      }
      refetch()
      toast.success("Data Delete Successfully")
    }
    catch(error){
      toast.error("Error Occur while deleting")
    }
  }

  const handleCreateBlogPost = async () => {
    try {
      const res = await createPostEditor(DefaultPostEditor).unwrap();
      if (res?.status === 201) {
        navigate(`/post-editor/${res.data.id}`);
      }
    } catch (err) {
      toast.error(err.data.detail);
    }
  };

  // CHECKBOX AND BULK ACTIONS
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allPostIds = sortedBlogPosts.map(post => post.id);
      setSelectedPosts(new Set(allPostIds));
    } else {
      setSelectedPosts(new Set());
    }
  };

  const handleCheckboxChange = (postId) => {
    setSelectedPosts(prevSelectedPosts => {
      const newSelectedPosts = new Set(prevSelectedPosts);
      if (newSelectedPosts.has(postId)) {
        newSelectedPosts.delete(postId);
      } else {
        newSelectedPosts.add(postId);
      }
      return newSelectedPosts;
    });
  };

  const handleApplyAction = async () => {
    if (bulkAction === 'delete') {
      handleDeleteModal("selected", true, null)
    }
  };

  return (
    <div>

      <div className="px-2 pl-6 pr-6">
        <div className="flex items-center justify-between py-3">
          <H1Header title={"My Blog Posts"} />
          <button
            className="px-5 py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light"
            disabled={isReadOnly}
            onClick={() => handleCreateBlogPost()}
          >
            {t("create-new-blog-post", "Créer un nouvel article de blog")}
          </button>
        </div>
      </div>

      <div className="bg-gray-100 p-4">
        <div className="mx-auto bg-white shadow-md rounded-md overflow-hidden">

          <div className="p-4">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-white uppercase bg-blue-100">
                <tr className="">
                  {[
                    "",
                    "Titre",
                    "Date",
                    "Actions",
                  ].map((heading, index) => (
                    <th
                      key={index}
                      className={`[&:nth-child(2)]:w-[50%] px-6 py-3 ${
                        index === 0
                          ? "rounded-s-lg"
                          : index === 3
                          ? "rounded-e-lg"
                          : ""
                      } text-base col`}
                    >
                      {index === 0 ? 
                        <input
                          type="checkbox"
                          checked={isAllSelected}
                          onChange={handleSelectAll}
                        />
                      : heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedBlogPosts?.map((blogPost) => (
                  <tr key={blogPost.id} className="odd:bg-white even:bg-black/10">
                    
                    <td
                      scope="col"
                      className="px-6 py-4 text-sm font-medium text-gray-900 break-all"
                    >
                      <input
                        type="checkbox"
                        checked={selectedPosts.has(blogPost.id)}
                        onChange={() => handleCheckboxChange(blogPost.id)}
                      />
                    </td>

                    <td
                      scope="col"
                      className="px-6 py-4 text-sm font-medium text-gray-900 break-all min-w-[60%]"
                    >
                      {blogPost.h1_title ? blogPost.h1_title : "(Pas de titre)"}
                     <p className="px-0 py-[4px] text-black min-w-[128px]
                       font-medium text-xs"
                      >
                        {blogPost.subject ? (blogPost.subject.length > 77 ? `${blogPost.subject.slice(0, 77)}...` : blogPost.subject) : "(Pas de sujet)"}
                      </p>
                    </td>

                    <td
                      scope="col"
                      className="px-6 py-4 text-xs font-medium text-gray-900 break-all"
                    >
                      { blogPost.creation_date === blogPost.last_update_date ? "Créé le" : "Dernière modification le" }
                      <br />
                      {formatDate(blogPost.last_update_date)}
                    </td>

                    <td
                      scope="col"
                      className="px-6 py-4 font-medium text-gray-900 flex gap-6 w-48"
                    >
                      <button className="px-5 py-[6px] hover:bg-blue-200 bg-blue-100 text-white rounded font-light" onClick={()=>navigate(`/post-editor/${blogPost.id}`)}>
                        <EditIcon />
                      </button>
                      <button className="px-5 py-[6px] hover:bg-[#f44336] bg-[#ff574b] text-white rounded font-light" onClick={()=>handleDeleteModal("single", true, blogPost.id)}>
                        <DeleteIcon />
                      </button>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex space-x-4 mt-4">
              <select 
                className="border rounded p-2 bg-white"
                value={bulkAction}
                onChange={(e) => setBulkAction(e.target.value)}
              >
                <option value="">{t("bulk-action" ,"Actions groupées")}</option>
                <option value="delete">{t("delete" ,"Supprimer")}</option>
              </select>
              <button 
                className="px-5 py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light"
                onClick={handleApplyAction}
              >
                {t("apply","Appliquer")}
              </button>
            </div>

          </div>
        </div>
      </div>

      <Dialog open={open} onClose={()=>handleDeleteModal("single", false, null)} className="relative z-[999]">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-[999] w-screen overflow-y-auto bg-black/50">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full sm:max-w-2xl sm:p-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  <div className="flex items-center gap-3">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-error/30 sm:mx-0 sm:h-10 sm:w-10">
                      <PriorityHighIcon
                        aria-hidden="true"
                        className="h-8 w-8 text-error"
                      />
                    </div>
                    <p className="text-base font-semibold leading-6 text-black/40">
                      {deletionType === "single" ? t("delete-blog-post", "Supprimer l'article de blog") : t("delete-selected-blog-post", "Supprimer les articles de blog sélectionnés")}
                    </p>
                  </div>
                </DialogTitle>
                <p className="text-base text-black text-left mt-5">
                  {deletionType === "single" ? "Êtes-vous sûr de vouloir supprimer votre article de blog ? Les données seront effacées et vous ne pourrez plus les récupérer." : "Êtes-vous sûr de vouloir supprimer ces articles de blog ? Les données seront effacées et vous ne pourrez plus les récupérer."}
                </p>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={deletionType === "single" ? () => handleDeleteBlogPost(selectedId, true) : () => handleDeleteSelectedBlogPosts()}
                    className="inline-flex w-full justify-center rounded-md bg-error px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  >
                    Supprimer
                  </button>
                  <button
                    type="button"
                    data-autofocus
                    onClick={()=>handleDeleteModal("single", false, null)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

    </div>
  )
}

export default AllPostEditor