import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { CustomLoader } from "../../shared/components/others";
import { Box } from "@mui/material";
import { 
  useGetPostEditorBlogMutation,
  // useDeleteSinglePostEditorBlogMutation,
  // useUpdateSinglePostEditorBlogMutation
} from "../../redux/slices/postEditorBlogSlice";

const UpdatePostEditor = () => {
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [getPostEditor, { isLoading: getLoading }] = useGetPostEditorBlogMutation();
  // const [deletePostEditor, { isLoading: deleteLoading }] =useDeleteSinglePostEditorBlogMutation();
  // const [updatePostEditor, {isLoading: updateLoading}] = useUpdateSinglePostEditorBlogMutation();

  const handleGetSingle = async (id) => {
    try {
      const res = await getPostEditor({id}).unwrap();
      const sanitizedData = Object.fromEntries(
        Object.entries(res?.data || {}).map(([key, value]) => [
          key,
          value === null ? "" : value
        ])
      );
      setBlogPost(sanitizedData);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetSingle(id)
  }, [id]);

  return (
    <Box>
      {getLoading || blogPost === null ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"65vh"}
        >
          <CustomLoader />
        </Box>
      ) : (
        <Box>
          <p>Post editor</p>
          {blogPost.nb_words}
        </Box>
      )}
    </Box>
  );
};

export default UpdatePostEditor;