const SelectProfile = ({ 
    formikProps,
    options,
    text, 
    name
}) => {
    return (
        <div>
            <label
                htmlFor={name}
                className="mb-2 block text-sm font-medium leading-6 text-gray-900"
            >
                {text}
            </label>
            <select
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                id={name}
                name={name}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
            >
                {options?.map((option) => (
                    <option key={option.name} value={option.name}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default SelectProfile;