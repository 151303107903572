import { apiSlice } from "./apiSlice";
import { ENDPOINTS } from "../../shared/constants/urls";

export const featuresApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFilter: builder.query({
      query: () => ({
        url: ENDPOINTS.GET_FEATURE_FILTER,
      }),
      providesTags: ["Feature"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getFeatureByQuery: builder.query({
      query: (query) => ({
        url: `${ENDPOINTS.GET_FEATURE}?feature_name=${query}`,
      }),
      provideTags: ["Feature"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllFeatures: builder.query({
      query: () => ({
        url: ENDPOINTS.GET_FEATURE,
      }),
      providesTags: ["Feature"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    generatePostH1Tag: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/post-h1-tag/${
          data.h1_tag ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generatePostOutline: builder.mutation({
      query: ({ stream, ...data }) => {
        const toRewriteParam = data.outline ? "to_rewrite=true" : "to_rewrite=false";
        const streamParam = stream ? "stream=true" : "stream=false";
    
        return {
          url: `${ENDPOINTS.CONTENT_GENERATION}/generate/post-outline/?${toRewriteParam}&${streamParam}`,
          method: "POST",
          body: data, // The body will not include stream
        };
      },
      extraOptions: { requiresAuth: true },
    }),     
    generatePostIntroduction: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/post-introduction/${
          data.introduction ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generatePostConclusion: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/post-conclusion/${
          data.conclusion ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generatePostPart: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/post-part/${
          data.part ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generatePostURLSlug: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/post-url-slug/${
          data.url_slug ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateProductDescription: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/product-description/${
          data.product_description ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateProductNames: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/product-names/${
          data.output_html_editor ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateProductCategoryDescription: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/product-category-description/${
          data.product_category_description ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateMetaTitle: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/meta-title/${
          data.meta_title ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateMetaDescription: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/meta-description/${
          data.meta_description ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateLexicalField: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/lexical-field/${
          data.lexical_field_table.length > 0 ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateSynonyms: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/synonyms/${
          data.synonyms_table ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateSemanticalEnrichment: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/semantical-enrichment/${
          data.enriched_text ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateSiloArchitecture: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/silo-architecture/${
          data.silo_architecture_table ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateKeywordsStrategy: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/keywords-strategy/${
          data.keywords_strategy_table ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateRelatedKeywords: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/related-keywords/${
          data.rekated_keywords_table ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateLongTailKeywords: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/long-tail-keywords/${
          data.long_tail_keywords_table ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateSearchIntent: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/search-intents/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateQuestionAsked: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/question-asked/${
          data.questions_asked_table ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateSocialMedia: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/social-media-description/${
          data.social_media_descriptions ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    generateNewsletter: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.CONTENT_GENERATION}/generate/newsletter-email/${
          data.newsletter_email ? "?to_rewrite=true" : "?to_rewrite=false"
        }`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    getAllPostOutline: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-outline/`,
      }),
      providesTags: ["post_outline"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllPostH1Tag: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-h1-tag/`,
      }),
      providesTags: ["post_tag"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllPostIntroduction: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-introduction/`,
      }),
      providesTags: ["post_introduction"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllPostConclusion: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-conclusion/`,
      }),
      providesTags: ["post_conclusion"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllPostPart: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-part/`,
      }),
      providesTags: ["post_part"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllPostUrlSlug: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-url-slug/`,
      }),
      providesTags: ["post_url_slug"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllProductDescription: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-description/`,
      }),
      providesTags: ["product_description"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllProductCategoryDescription: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-category-description/`,
      }),
      providesTags: ["product_category_description"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllMetaTitle: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-title/`,
      }),
      providesTags: ["meta_title"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllMetaDescription: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-description/`,
      }),
      providesTags: ["meta_description"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllLexicalField: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/lexical-field/`,
      }),
      providesTags: ["LexicalField"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllSynonyms: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/synonyms/`,
      }),
      providesTags: ["Synonyms"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllSemantical: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/semantical-enrichment/`,
      }),
      providesTags: ["Semantical"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllSilo: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/silo-architecture/`,
      }),
      providesTags: ["Silo"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllKeywordStrategy: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/keywords-strategy/`,
      }),
      providesTags: ["KeywordStrategy"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllKeywordIdeas: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/related-keywords/`,
      }),
      providesTags: ["KeywordIdeas"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllLongTail: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/long-tail-keywords/`,
      }),
      providesTags: ["LongTail"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllUserQuestion: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/question-asked/`,
      }),
      providesTags: ["UserQuestion"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllKeywordAnalyzer: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/search-intents/`,
      }),
      providesTags: ["KeywordAnalyzer"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllProductNames: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-names/`,
      }),
      providesTags: ["ProductNames"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllSocialMedia: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/social-media-description/`,
      }),
      providesTags: ["SocialMedia"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    getAllNewsLetter: builder.query({
      query: () => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/newsletter-email/`,
      }),
      providesTags: ["NewsLetter"],
      keepUnusedDataFor: 5,
      extraOptions: { requiresAuth: true },
    }),
    createPostOutline: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-outline/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createPostH1Tag: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-h1-tag/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createPostIntroduction: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-introduction/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createPostConclusion: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-conclusion/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createPostPart: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-part/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createPostURLSlug: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-url-slug/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createProductDescription: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-description/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createProductName: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-names/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createProductCategoryDescription: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-category-description/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createMetaTitle: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-title/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createMetaDescription: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-description/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createLexicalField: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/lexical-field/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createSynonyms: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/synonyms/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createSemanticalEnrichment: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/semantical-enrichment/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createSiloArchitecture: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/silo-architecture/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createKeywordsStrategy: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/keywords-strategy/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createRelatedKeywords: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/related-keywords/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createLongTailKeywords: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/long-tail-keywords/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createSearchIntent: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/search-intents/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createQuestionAsked: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/question-asked/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createSocialMedia: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/social-media-description/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    createNewsLetter: builder.mutation({
      query: (data) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/newsletter-email/`,
        method: "POST",
        body: data,
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSinglePostOutline:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-outline/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSinglePostOutline:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-outline/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSinglePostOutline:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-outline/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSinglePostIntroduction:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-introduction/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSinglePostIntroduction:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-introduction/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSinglePostIntroduction:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-introduction/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSinglePostH1Tag:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-h1-tag/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSinglePostH1Tag:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-h1-tag/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSinglePostH1Tag:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-h1-tag/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSinglePostConclusion:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-conclusion/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSinglePostConclusion:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-conclusion/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSinglePostConclusion:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-conclusion/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSinglePostPart:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-part/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSinglePostPart:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-part/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSinglePostPart:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-part/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSinglePostURLSlug:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-url-slug/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSinglePostURLSlug:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-url-slug/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSinglePostURLSlug:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/post-url-slug/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),

    getSingleProductDescription:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-description/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleProductDescription:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-description/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleProductDescription:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-description/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleProductCategoryDescription:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-category-description/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleProductCategoryDescription:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-category-description/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleProductCategoryDescription:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-category-description/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleMetaTitle:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-title/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleMetaTitle:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-title/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleMetaTitle:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-title/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleMetaDescription:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-description/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleMetaDescription:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-description/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleMetaDescription:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/meta-description/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),

    getSingleSemanticalEnrichment:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/semantical-enrichment/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleSemanticalEnrichment:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/semantical-enrichment/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleSemanticalEnrichment:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/semantical-enrichment/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),

    getSingleSiloArchitecture:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/silo-architecture/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleSiloArchitecture:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/silo-architecture/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleSiloArchitecture:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/silo-architecture/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),

    getSingleProductName:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-names/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleProductName:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-names/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleProductName:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-names/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleSocialMediaDescription:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/social-media-description/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleSocialMediaDescription:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/social-media-description/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleSocialMediaDescription:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/social-media-description/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),

    getSingleNewsletter:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/newsletter-email/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleNewsletter:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/newsletter-email/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleNewsletter:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/newsletter-email/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleLexicalField:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/lexical-field/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleLexicalField:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/lexical-field/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleLexicalField:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/lexical-field/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleSynonymsField:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/synonyms/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleSynonymsField:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/synonyms/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleSynonymsField:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/synonyms/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleKeywordStrategy:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/keywords-strategy/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleKeywordStrategy:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/keywords-strategy/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleKeywordStrategy:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/keywords-strategy/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleKeywordIdeas:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/related-keywords/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleKeywordIdeas:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/related-keywords/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleKeywordIdeas:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/related-keywords/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleKeywordTail:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/long-tail-keywords/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleKeywordTail:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/long-tail-keywords/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleKeywordTail:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/long-tail-keywords/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleUserQuestion:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/question-asked/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleUserQuestion:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/question-asked/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleUserQuestion:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/question-asked/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleSearchIntent:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/search-intents/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSearchIntent:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/search-intents/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSearchIntent:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/search-intents/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
    getSingleProductNames:builder.mutation({
      query: ({id}) => ({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-names/${id}/`,
        method: "GET",
      }),
      extraOptions: { requiresAuth: true },
    }),
    updateSingleProductNames:builder.mutation({
      query:(data)=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-names/${data.id}/`,
        method: "PUT",
        body:data
      }),
      extraOptions: { requiresAuth: true },
    }),
    deleteSingleProductNames:builder.mutation({
      query:({id})=>({
        url: `${ENDPOINTS.FEATURE_EDITOR}/product-names/${id}/`,
        method: "DELETE"
      }),
      extraOptions: { requiresAuth: true },
    }),
  }),
});

export const {
  useGetFeatureFilterQuery,
  useGetFeatureByQueryQuery,
  useGetAllFeaturesQuery,
  useGeneratePostOutlineMutation,
  useGeneratePostIntroductionMutation,
  useGeneratePostConclusionMutation,
  useGeneratePostH1TagMutation,
  useGeneratePostPartMutation,
  useGeneratePostURLSlugMutation,
  useGenerateProductDescriptionMutation,
  useGenerateProductCategoryDescriptionMutation,
  useGenerateMetaTitleMutation,
  useGenerateMetaDescriptionMutation,
  useGenerateLexicalFieldMutation,
  useGenerateSynonymsMutation,
  useGenerateSemanticalEnrichmentMutation,
  useGenerateSiloArchitectureMutation,
  useGenerateKeywordsStrategyMutation,
  useGenerateRelatedKeywordsMutation,
  useGenerateLongTailKeywordsMutation,
  useGenerateSearchIntentMutation,
  useGenerateProductNamesMutation,
  useGenerateQuestionAskedMutation,
  useGenerateSocialMediaMutation,
  useGenerateNewsletterMutation,
  useCreatePostOutlineMutation,
  useCreateKeywordsStrategyMutation,
  useCreateLexicalFieldMutation,
  useCreateLongTailKeywordsMutation,
  useCreateMetaDescriptionMutation,
  useCreateMetaTitleMutation,
  useCreateNewsLetterMutation,
  useCreatePostConclusionMutation,
  useCreatePostIntroductionMutation,
  useCreatePostPartMutation,
  useCreatePostH1TagMutation,
  useCreatePostURLSlugMutation,
  useCreateProductCategoryDescriptionMutation,
  useCreateProductDescriptionMutation,
  useCreateProductNameMutation,
  useCreateQuestionAskedMutation,
  useCreateRelatedKeywordsMutation,
  useCreateSearchIntentMutation,
  useCreateSemanticalEnrichmentMutation,
  useCreateSiloArchitectureMutation,
  useCreateSocialMediaMutation,
  useCreateSynonymsMutation,
  useGetAllPostOutlineQuery,
  useGetAllPostH1TagQuery,
  useGetAllPostIntroductionQuery,
  useGetAllPostConclusionQuery,
  useGetAllPostPartQuery,
  useGetAllPostUrlSlugQuery,
  useGetAllProductDescriptionQuery,
  useGetAllProductCategoryDescriptionQuery,
  useGetAllMetaTitleQuery,
  useGetAllMetaDescriptionQuery,
  useGetAllLexicalFieldQuery,
  useGetAllSynonymsQuery,
  useGetAllSemanticalQuery,
  useGetAllSiloQuery,
  useGetAllKeywordStrategyQuery,
  useGetAllKeywordIdeasQuery,
  useGetAllLongTailQuery,
  useGetAllUserQuestionQuery,
  useGetAllKeywordAnalyzerQuery,
  useGetAllProductNamesQuery,
  useGetAllSocialMediaQuery,
  useGetAllNewsLetterQuery,
  useGetSinglePostOutlineMutation,
  useUpdateSinglePostOutlineMutation,
  useDeleteSinglePostOutlineMutation,
  useGetSinglePostIntroductionMutation,
  useDeleteSinglePostIntroductionMutation,
  useUpdateSinglePostIntroductionMutation,
  useDeleteSinglePostH1TagMutation,
  useGetSinglePostH1TagMutation,
  useUpdateSinglePostH1TagMutation,
  useDeleteSinglePostConclusionMutation,
  useUpdateSinglePostConclusionMutation,
  useGetSinglePostConclusionMutation,
  useDeleteSinglePostPartMutation,
  useUpdateSinglePostPartMutation,
  useGetSinglePostPartMutation,
  useDeleteSinglePostURLSlugMutation,
  useUpdateSinglePostURLSlugMutation,
  useGetSinglePostURLSlugMutation,
  useDeleteSingleProductDescriptionMutation,
  useUpdateSingleProductDescriptionMutation,
  useGetSingleProductDescriptionMutation,
  useDeleteSingleProductCategoryDescriptionMutation,
  useUpdateSingleProductCategoryDescriptionMutation,
  useGetSingleProductCategoryDescriptionMutation,
  useDeleteSingleMetaTitleMutation,
  useUpdateSingleMetaTitleMutation,
  useGetSingleMetaTitleMutation,
  useDeleteSingleMetaDescriptionMutation,
  useUpdateSingleMetaDescriptionMutation,
  useGetSingleMetaDescriptionMutation,
  useDeleteSingleSemanticalEnrichmentMutation,
  useUpdateSingleSemanticalEnrichmentMutation,
  useGetSingleSemanticalEnrichmentMutation,
  useDeleteSingleSiloArchitectureMutation,
  useUpdateSingleSiloArchitectureMutation,
  useGetSingleSiloArchitectureMutation,
  useDeleteSingleProductNameMutation,
  useUpdateSingleProductNameMutation,
  useGetSingleProductNameMutation,
  useDeleteSingleSocialMediaDescriptionMutation,
  useUpdateSingleSocialMediaDescriptionMutation,
  useGetSingleSocialMediaDescriptionMutation,
  useDeleteSingleNewsletterMutation,
  useUpdateSingleNewsletterMutation,
  useGetSingleNewsletterMutation,
  useGetSingleLexicalFieldMutation,
  useUpdateSingleLexicalFieldMutation,
  useDeleteSingleLexicalFieldMutation,
  useGetSingleSynonymsFieldMutation,
  useUpdateSingleSynonymsFieldMutation,
  useDeleteSingleSynonymsFieldMutation,
  useGetSingleKeywordStrategyMutation,
  useUpdateSingleKeywordStrategyMutation,
  useDeleteSingleKeywordStrategyMutation,
  useGetSingleKeywordIdeasMutation,
  useUpdateSingleKeywordIdeasMutation,
  useDeleteSingleKeywordIdeasMutation,
  useGetSingleKeywordTailMutation,
  useUpdateSingleKeywordTailMutation,
  useDeleteSingleKeywordTailMutation,
  useGetSingleUserQuestionMutation,
  useUpdateSingleUserQuestionMutation,
  useDeleteSingleUserQuestionMutation,
  useGetSingleSearchIntentMutation,
  useUpdateSearchIntentMutation,
  useDeleteSearchIntentMutation,
  useGetSingleProductNamesMutation,
  useUpdateSingleProductNamesMutation,
  useDeleteSingleProductNamesMutation

} = featuresApiSlice;