import { FieldErrorMessage } from "../others"

const InputProfile = ({ 
    formikProps,
    type,
    text, 
    name, 
    placeholder,
    disabled=false
}) => {
    return (
        <div>
            <label
                htmlFor={name}
                className="mb-2 block text-sm font-medium leading-6 text-gray-900"
            >
                {text}
            </label>
            <input
                className="w-full bg-white p-2.5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 h-fit overflow-hidden disabled:bg-gray_local-400/30"
                type={type}
                placeholder={placeholder}
                variant="outlined"
                value={formikProps.values[name]}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                name={name}
                disabled={disabled}
            />
            {/* <p className="text-red font-light text-xs text-right">
                {formikProps.touched[name] && Boolean(formikProps.errors[name])}
            </p> */}
            <FieldErrorMessage name={name} />
        </div>
    )
}

export default InputProfile;