import { useState } from 'react'
import { WritingStyle, Company, Product } from './generationVariables'


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ManageVariable = () => {
  const [value, setValue] = useState("Writing Style");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { name: "Writing Style", value: "Writing Style" },
    { name: "Entreprise", value: "Entreprise" },
    { name: "Product", value: "Product" },
  ];

  return (
    <div>
      
      <div>
        <div className="sm:hidden">
          <select
            id="tabs"
            name="tabs"
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            className="block w-full bg-blue-50 text-white rounded-md border-gray-300 focus:outline-none p-2"
          >
            {tabs.map((tab) => (
              <option key={tab.value} value={tab.value}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            aria-label="Tabs"
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow w-full"
          >
            {tabs.map((tab, tabIdx) => (
              <button
                key={tab.value}
                onClick={() => handleChange(tab.value)}
                className={classNames(
                  tab.value === value
                    ? "text-blue-50 border-b border border-blue-50 hover:bg-white"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-black/10"
                )}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.value === value ? "bg-blue-50" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div className="mt-6">
        {value === "Writing Style" && <WritingStyle/>}
        {value === "Entreprise" && <Company/>}
        {value === "Product" && <Product />}
      </div>

    </div>
  )
}

export default ManageVariable