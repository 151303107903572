import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Formik } from "formik";
import { ProductInitialValues, ProductSchema } from "../helper";
import {
  useDeleteProductVariableMutation,
  useGetAllProductVariableQuery,
  useGetSingleProductMutation,
  useProductVariableMutation,
  useUpdateSingleProductMutation,
} from "../../../redux/slices/profileApiSlice";
import { ClipLoader } from "react-spinners";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { toast } from "react-toastify";
import DeleteDialog from "../../../shared/components/generationVariables/DeleteDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { InputProfile } from "../../../shared/components/profile";

const Product = () => {
  const { t } = useTranslation();
  const [product, { isLoading }] = useProductVariableMutation();
  const [counter, setCounter] = useState(0);
  const [selectId, setSelectId] = useState("");
  const [onEdit, setOnEdit] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteProductVariable, { isLoading: loadingDelete }] =
    useDeleteProductVariableMutation();
  const {
    data: productVariables,
    isLoading: loading,
    refetch,
  } = useGetAllProductVariableQuery();
  const [singleProduct, { isLoading: loadingSingleProduct }] =
    useGetSingleProductMutation();
  const [updateProduct, { isLoading: updateProductLoading }] =
    useUpdateSingleProductMutation();

  useEffect(() => {
    refetch();
  }, [counter]);

  // To open the delete modal
  const handleDeleteProductVariable = async (id) => {
    setOpen(true);
    setSelectId(id);
  };

  // To delete the object
  const handleDelete = async () => {
    try {
      const res = await deleteProductVariable(selectId);
      if (res) {
        toast.success("Deleted successfully");
        setOpen(false);
        setOnEdit(false);
        setEditProduct(null);
        refetch();
      }
    } catch (error) {
      toast.error(error?.data?.detail || "Something went wrong");
      setOpen(false);
    }
  };

  // To edit the product
  const handleProductEdit = async (id) => {
    if (id) {
      try {
        setOnEdit(true);
        const res = await singleProduct({ id }).unwrap();
        setEditProduct(res);
      } catch (error) {}
    }
  };

  // To create a new product
  const handleCreateNew = async (resetForm) => {
    setOnEdit(false);
    setEditProduct(null);
    refetch();
    resetForm();
  };

  return (
    <div className="bg-white py-10 rounded-2xl px-6 mx-auto shadow-default items-center">

      <div className="flex items-center">
        <ViewInArIcon sx={{ width: "35px", height: "35px" }} />
        <p className="md:text-[28px] sm:text-lg font-medium ml-3">Product</p>
      </div>

      <div className="grid md:grid-cols-2 gap-8 mt-4">
        <Formik
          initialValues={editProduct ? editProduct : ProductInitialValues}
          enableReinitialize={true}
          validationSchema={ProductSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              if (onEdit) {
                const res = await updateProduct(values).unwrap();
                if (res?.status === 200) {
                  toast.success("Update successfully");
                  setCounter(counter + 1);
                }
              } else {
                const res = await product(values).unwrap();
                setCounter((prev) => prev + 1);
                if (res?.status === 201) {
                  toast.success("Created successfully");
                  handleProductEdit(res.data.id);
                }
              }
            } catch (error) {
              toast?.error(error?.data?.detail);
            }
          }}
        >
          {(props) => (
            <div className="w-full flex items-center justify-center">
              <form onSubmit={props.handleSubmit} className="w-full">
                <div className="grid md:grid-cols-1 gap-y-5 w-full">
                  
                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("product-key", "Nom du produit (utilisé pour vous permettre d'identifier le produit)")}
                      name="key"
                      placeholder={t("example-product-key", "Ex: BotBlogR - abonnement ultimate")}
                    />
                  </div>

                  <div>
                    <div className="flex items-center gap-9">
                      <label className="relative cursor-pointer block w-fit">
                        <input
                          className="peer sr-only"
                          checked={props.values.is_default}
                          onChange={props.handleChange}
                          name="is_default"
                          color="primary"
                        />
                        <div className="peer h-[14px] w-9 rounded-full bg-gray_local-400 after:absolute after:top-[-3px] after:left-0 after:h-5 after:w-5 after:rounded-full after:shadow-2xl after:bg-[#e1e1e1] after:transition-all after:content-[''] peer-checked:bg-[#316694] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none" />
                      </label>
                      <p className="text-sm">{t("choose-as-default-product", "Choisir comme produit par défaut")}</p>
                    </div>
                  </div>

                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("product-name", "Nom du produit (utilisé pour la génération)")}
                      name="name"
                      placeholder={t("example-product-name", "Ex: Abonnement Ultimate à BotBlogR")}
                    />
                  </div>

                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("product-description", "Description du produit")}
                      name="description"
                      placeholder={t("example-product-description", "Ex: Toutes les fonctionalités de BotBlogR : un éditeur d'article ultime ...")}
                    />
                  </div>

                  <div className="w-full">
                    <InputProfile
                      formikProps={props}
                      type="text"
                      text={t("product-link", "Lien vers le produit")}
                      name="link"
                      placeholder={t("example-product-link", "Ex: https://botblogr.com/pricing")}
                    />
                  </div>

                  <div className="w-full flex items-center justify-between">
                    <button
                      className="px-5 py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light"
                      onClick={props.handleSubmit}
                      disabled={isLoading || updateProductLoading}
                    >
                      {isLoading || updateProductLoading ? (
                        <ClipLoader size={25} color="white" />
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  
                    <button
                      type="button"
                      className="px-5 py-[6px] hover:bg-success_hover bg-success text-white rounded font-light"
                      onClick={() => handleCreateNew(props.resetForm)}
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <div className="container">
          <div className="mt-6">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs text-white uppercase bg-blue-100">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 rounded-s-lg text-base"
                    >
                      name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 rounded-e-lg w-48 text-base"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productVariables?.map((item) => (
                    <>
                      <tr className="odd:bg-white even:bg-black/10">
                        <td
                          scope="col"
                          className="px-6 py-4 text-sm font-medium text-gray-900 break-all"
                        >
                          {item.key}
                        </td>
                        <td
                          scope="col"
                          className="px-6 py-4 font-medium text-gray-900 flex gap-6 w-48"
                        >
                          <button
                            className="px-5 py-[6px] hover:bg-blue-200 bg-blue-100 text-white rounded font-light"
                            onClick={() => handleProductEdit(item?.id)}
                          >
                            <EditIcon />
                          </button>
                          <button
                            className="px-5 py-[6px] hover:bg-[#f44336] bg-[#ff574b] text-white rounded font-light"
                            onClick={() =>
                              handleDeleteProductVariable(item?.id)
                            }
                          >
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <DeleteDialog
        open={open}
        text="Are you sure you want to Delete this Product Variable?"
        handleDelete={handleDelete}
        setOpen={setOpen}
        loading={loadingDelete}
        title="Delete Product Variable"
      />

    </div>
  );
};

export default Product;