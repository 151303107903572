import React, {useCallback, useState} from 'react'
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ArrowForward } from "@mui/icons-material";

const GetStartedGuide = ({
    guideInfo, 
    updateGuideInfo,
    handleClose,
    refetch
}) => {
    const { t } = useTranslation();
    const [guideStep, setGuideStep] = useState(1);

    const progressGuide = async (step, notEnd=true) => {
        try {
            if (guideInfo.guide_step < step) {
                const payload = {
                    "show_guide": guideInfo.show_guide,
                    "guide_step": step-1
                };
                await updateGuideInfo(payload);
            }
            if (notEnd) {
                setGuideStep(step);
            }
            refetch();
        } catch (error) {
            toast.error(error?.data?.detail || t("something-went-wrong"));
        }
    };

    const guideContent = [
        {
            "step": 1,
            "title": t("guide-content-title-1", "Introduction à BotBlogR"),
            "subtitle": t("guide-content-subtitle-1", "Les bases à savoir sur le logiciel, son organisation et son fonctionnement"),
            "text": t("guide-content-text-1", "Texte intro"),
            "link": "https://www.youtube.com/watch?v=48Y9our3mig"
        },
        {
            "step": 2,
            "title": t("guide-content-title-2", "Intégrer sa clé OpenAI"),
            "subtitle": t("guide-content-subtitle-2", "Pour commencer à générer"),
            "text": t("guide-content-text-2", "Texte intégrer openai"),
            "link": "https://www.youtube.com/watch?v=B"
        },
        {
            "step": 3,
            "title": t("guide-content-title-3", "Style d'écriture, identité et produits"),
            "subtitle": t("guide-content-subtitle-3", "Découvrez comment intégrer son propre style à tous nos outils"),
            "text": t("guide-content-text-3", "Texte generation variables"),
            "link": "https://www.youtube.com/watch?v=C"
        },
        {
            "step": 4,
            "title": t("guide-content-title-4", "Outils simples"),
            "subtitle": t("guide-content-subtitle-4", "Découvrez les différents outils mis à votre disposition"),
            "text": t("guide-content-text-4", "Texte outils simples"),
            "link": "https://www.youtube.com/watch?v=D"
        },
        {
            "step": 5,
            "title": t("guide-content-title-5", "Éditeur d'articles"),
            "subtitle": t("guide-content-subtitle-5", "Apprenez tout ce qu'il y a à savoir sur l'éditeur de post"),
            "text": t("guide-content-text-5", "Texte éditeur"),
            "link": "https://www.youtube.com/watch?v=E"
        }       
    ]

    const guideSteps = useCallback(
        (item) => {
          const { step, title, subtitle, link, text } = item;
    
          return (
            <div
              className={`px-2 py-1 my-5 rounded-lg relative min-h-[90px] [&:nth-child(1)]:mt-0 last:mb-0 ${
                step <= guideInfo.guide_step
                  ? "bg-blue-50 text-white"
                  : "bg-[#EEEEEE] text-black"
              } ${
                guideStep === step
                  && "border-solid border-[#000000] border-2"
              }`}
              key={step}
            >
              <span className="break-words pr-12 block text-lg font-sans antialiased font-semibold tracking-tight">
                Step {step} : {title}
              </span>
              <p className="px-0 py-[4px] max-w-sm text-base mt-3 font-normal text-gray-700">
                {subtitle}
              </p>
            </div>
          );
        },
        [guideStep, guideInfo]
    );

    return (
        <div className="bg-white rounded-lg pb-7 shadow-default pt-2 px-3 xl:col-span-5 col-span-3">
            
            <div className="flex items-center justify-between mb-4">
                <div>
                <h2 className="text-xl font-semibold">Get started Guide</h2>
                </div>
                <div>
                <button type="button" className="" onClick={() => handleClose()}>
                    <span className="sr-only">Close</span>
                    <CloseIcon style={{ height: "38px", width: "38px" }} />
                </button>
                </div>
            </div>

            <div className="grid xl:grid-cols-5 xl:col-span-5 col-span-3 md:gap-5 gap-y-5 relative">
                
                <div className="xl:col-span-2 col-span-5  pt-0">
                    <div className="rounded-lg bg-white py-4 pb-0 pt-0 h-full group">
                    <div className="w-full px-3 mt-0 max-h-[584px] overflow-y-auto">
                        {guideContent?.map((item) => guideSteps(item))}
                    </div>
                    </div>
                </div>

                <div className="xl:col-span-3 col-span-5  h-full relative">
                
                    <div className=" overflow-hidden py-4 pb-0 rounded-md max-h-[528px] overflow-y-auto bg-[#EEEEEE]">
                        <div className="px-4">
                            <h2 className="text-2xl font-semibold mb-5">
                                {guideContent[guideStep-1].title}
                            </h2>
                            <video
                                className="w-full rounded-md max-h-[385px] bg-white"
                                controls
                                muted
                            >
                                <source src={guideContent[guideStep-1].link} />
                            </video>
                            <p className="text-base my-5 min-h-[75px]">
                                {guideContent[guideStep-1].text}
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center justify-between bg-[#DDDDDD] py-2 px-4  w-full z-50">
                        
                        {guideStep > 1 ? (
                            <button
                                className="px-5 py-[6px] text-[1.15rem] bg-blue-50 text-white rounded font-light flex items-center hover:bg-blue-200"
                                onClick={() =>
                                    progressGuide(guideStep - 1)
                                }
                            >
                                <ArrowBackIcon /> Previous
                            </button>
                        ) : (
                            <div></div>
                        )}

                        {guideStep < 5 ? (
                            <button
                                className="px-5 py-[6px] text-[1.15rem] bg-blue-50 text-white rounded font-light hover:bg-blue-200"
                                onClick={() => progressGuide(guideStep + 1)}
                            >
                                {t("next", "Suivant")}
                                <ArrowForward />
                            </button>
                        ) : (
                            <button
                                className="px-5 py-[6px] text-[1.15rem] bg-blue-50 text-white rounded font-light hover:bg-blue-200"
                                onClick={() => {
                                    progressGuide(guideStep + 1, false)
                                    handleClose(true);
                                }}
                            >
                                {t("finish", "Terminer")}
                            </button>
                        )}

                    </div>

                </div>
            </div>

            </div>
    )
}

export default GetStartedGuide