import React, {useState} from 'react';
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { H1Header } from '../../shared/components/others';
import PlansOptions from '../../shared/components/payment/plansOptions';
import SelectPlan from '../../shared/components/payment/selectPlan';

const Pricing = () => {
    const [open, setOpen] = useState(false);
    const [newSubscription, setNewSubscription] = useState(null);

    const handleNewSubscriptionSelection = async (new_sub) => {
        setNewSubscription(new_sub);
        setOpen(true);
    }

    return (
        <div className='px-2 pl-6 pr-6'>
            
            <div className="py-3 flex flex-col justify-center items-center">
                <H1Header title={"Tarifs"} />
            </div>

            <PlansOptions
                isPage={true}
                selectSubscription={handleNewSubscriptionSelection}
            />

            <Dialog open={open} onClose={()=>setOpen(false)} className="relative z-[999]">
                <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-[999] w-screen overflow-y-auto bg-black/50">
                    <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                        <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in max-sm:10/12 md:w-8/12 lg:w-6/12 sm:p-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >

                        <button
                        className="absolute top-2 right-3 text-gray-400 hover:text-gray-600 focus:outline-none text-2xl"
                        onClick={() => setOpen(false)}
                        >
                        <span className="sr-only">Close</span>
                        &#10005;
                        </button>

                        <div className="">
                            <DialogTitle
                            as="h3"
                            className="text-xl font-semibold leading-6 text-gray-900"
                            >
                                Confirmer le changement d'abonnement
                            </DialogTitle>

                            <SelectPlan
                                newPlan={newSubscription}
                                returnToOptions={() => setOpen(false)}
                            />
                        </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

        </div>
    );
}

export default Pricing;