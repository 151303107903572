import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import handleLogout from '../../redux/logout';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from "react-redux";
import useMobileView from "../../hooks/mobileView"

function Header({ handleDrawer }) {
  const userInfo = useSelector((state) => state?.auth).userInfo;
  const navigate = useNavigate();
  const isMobile = useMobileView();
  const dispatch = useDispatch();

  const planText = (userInfo.plan === "Ultimate" ? "Manage plan" : "Upgrade Plan")

  return (
    <>
      <nav className="bg-white fixed border-gray-200 z-40 w-full shadow-2">
        <div className="flex flex-wrap items-center justify-between mx-auto p-4 sm:pl-9 pl-6 sm:pr-7 pr-2">
          <div className='flex flex-wrap items-center'>
            <button data-collapse-toggle="navbar-dropdown" type="button" className="items-center p-2 w-10 h-10 justify-center text-sm sm:ml-7 text-[#0000008a] xl:hidden block" aria-controls="navbar-dropdown" aria-expanded="false" onClick={handleDrawer}>
              <span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
              </svg>
            </button>
          </div>
          <div className='flex items-center sm:gap-6 gap-3 justify-between'>
            <button className='px-5 py-[6px] hover:bg-blue-200 *:
             bg-blue-100 text-white rounded font-light' onClick={() => navigate("/pricing")}> {planText}</button>
            <button className='h-8 w-8 flex items-center justify-center hover:text-blue-50 bg-Lightwhite rounded-full  text-gray font-light' onClick={() => navigate('/profile')}>
              <PersonIcon sx={{ fontSize: "20px" }} />
            </button>
            <button className='h-8 w-8 flex items-center justify-center hover:text-blue-50 bg-Lightwhite rounded-full  text-gray font-light' onClick={() => handleLogout(dispatch)}>
              <LogoutIcon sx={{
                fontSize: "20px"
              }} />
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Header;