import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react'
import { ChangeEmailInitialValues, ChangeEmailSchema } from '../helper';
import { ClipLoader } from 'react-spinners';
import { useChangeEmailMutation } from '../../../redux/slices/profileApiSlice';
import { toast } from 'react-toastify';
import { InputProfile } from "../../../shared/components/profile"

const ChangeEmail = () => {
  const { t } = useTranslation();
  const [update, { isLoading }] = useChangeEmailMutation();
  
  return (
    <>
    <Formik
      initialValues={ChangeEmailInitialValues}
      enableReinitialize={true}
      validationSchema={ChangeEmailSchema}
      onSubmit={async (values) => {
        const response=await update(values);
        if(response?.data?.status===200){
          toast.success(response?.data?.message)
        } else {
          toast.error(response?.error?.data?.detail)
        }

      }}
    >
      {(props) => (
        <div className="mx-auto h-[70vh] flex items-center justify-center bg-white shadow-default rounded-2xl">
          <div className="w-[630px]">
          <form onSubmit={props.handleSubmit} className="w-full">
            <div className="grid md:grid-cols-2 gap-x-11 gap-5 w-full">

              <div className="col-span-2">
                <InputProfile
                  formikProps={props}
                  type="text"
                  text={t("enter-old-email", "Entrez votre ancien email")}
                  name="old_email"
                  placeholder={t("enter-old-email", "Entrez votre ancien email")}
                />
              </div>

              <div className="col-span-2">
                <InputProfile
                  formikProps={props}
                  type="text"
                  text={t("enter-new-email", "Entrez votre nouvel email")}
                  name="new_email"
                  placeholder={t("enter-new-email", "Entrez votre nouvel email")}
                />
              </div>

              <div className="col-span-2">
                <button className="px-5 w-full py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light">
                  {isLoading ? (
                    <ClipLoader size={25} color="white" />
                  ) : (
                    "Change Email"
                  )}
                </button>
              </div>
              
            </div>
          </form>
          </div>
        </div>
      )}
    </Formik>
  </>
  )
}

export default ChangeEmail