import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Field } from "formik";
import { FieldErrorMessage } from "../others";

const GVModalTemplate = ({ 
  open, 
  handleClose,
  formikProps,
  generationVariablesList,
  fieldName,
  modalTitle,
  modalIcon,
  modalDescription,
  noSelectionText
}) => {
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <img src={modalIcon} width={40} height={40} />
          <Typography variant="h3" fontSize={28}>
            {modalTitle} (Optional)
          </Typography>
        </Box>

        <Typography>
            {modalDescription}
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <Box pt={2}>
              <InputLabel shrink sx={{ paddingRight: "100%" }}>
                {modalTitle}
              </InputLabel>
              <Field
                as={Select}
                name={fieldName}
                displayEmpty={true}
                sx={{ width: "100% !important", textAlign: "start" }}
                error={
                  formikProps.touched[fieldName] && Boolean(formikProps.errors[fieldName])
                }
              >
                <MenuItem value="">--{noSelectionText}--</MenuItem>
                {generationVariablesList?.map((w) => {
                    return <MenuItem key={`${fieldName}_${w.id}`} value={w.id}>{w.key}</MenuItem>;
                })}
              </Field>
              <FieldErrorMessage name={fieldName} />
            </Box>
          </Grid>
        </Grid>

      </Box>
    </Modal>
  );
};

export default GVModalTemplate;
