import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { signUpInitialValues as initialValues, SignUpSchema as Schema } from "./helper";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRegisterMutation } from "../../redux/slices/authApiSlice";
import { InputAuth, ButtonAuth, GoogleAuth } from "../../shared/components/auth"

const SignUp = () => {
  const { t } = useTranslation();
  const [register, { isLoading }] = useRegisterMutation();
  const navigate = useNavigate();
  const [param, setParam] = useSearchParams();

  const ref = useRef();
  useEffect(()=>{
    const  value = param.get('referral')
    if(value)
    {
      ref.current.setFieldValue("refferal",value);
    }
  },[])

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        innerRef={ref}
        validationSchema={Schema}
        onSubmit={async (values, { resetForm }) => {
          try {
          
            const res = await register(values).unwrap();
            if (res?.message.includes("successful")) {
              toast.info("Un email de vérification vous a été envoyé sur votre adresse. Cliquez sur le lien à l'intérieur pour activer votre compte !")
              resetForm();
            }
          } catch (error) {
            toast?.error(error?.data?.detail);
          }
        }}
      >
        {(props) => (
          <div>
            <div>
              <p
                className="text-sm leading-6 text-gray-500 flex items-center gap-3"
                onClick={() => navigate("/login")}
              >
                {t(
                  "already-have-account",
                  "Vous avez déjà un compte ?"
                )}
                <span className="cursor-pointer font-semibold text-blue-600">
                  {t("signin")}
                </span>
              </p>
            </div>
            <div className="mt-5">
              <form action="#" method="POST" className="space-y-6">
                
                <InputAuth
                  formikProps={props}
                  type="text"
                  text={t("enter-first-name", "Entrez votre prénom")}
                  name="first_name"
                  placeholder={t("enter-first-name", "Entrez votre prénom")}
                />

                <InputAuth
                  formikProps={props}
                  type="text"
                  text={t("enter-last-name", "Entrez votre nom de famille")}
                  name="last_name"
                  placeholder={t("enter-last-name", "Entrez votre nom de famille")}
                />

                <InputAuth
                  formikProps={props}
                  type="text"
                  text={t("enter-email", "Entrez votre email")}
                  name="email"
                  placeholder={t("enter-email", "Entrez votre email")}
                />

                <InputAuth
                  formikProps={props}
                  type="password"
                  text={t("enter-password", "Entrez votre mot de passe")}
                  name="password"
                  placeholder={t("enter-password", "Entrez votre mot de passe")}
                />

                <InputAuth
                  formikProps={props}
                  type="text"
                  text={t("enter-affiliate-code", "Entrez votre code affilié")}
                  name="refferal"
                  placeholder={t("enter-affiliate-code", "Entrez votre code affilié")}
                />

                <ButtonAuth
                  action={props.handleSubmit}
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
                  text={t("signup")}
                />

              </form>

              <GoogleAuth
                text={t("register", "S'inscrire")}
                btnText={t("register-google", "S'inscrire via Google")}
              />

            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default SignUp;