import React, { useState } from "react";
import { H1Header } from '../../shared/components/others';
import UserGuideStep from "../../shared/components/docs/UserGuideStep"
import { useTranslation } from "react-i18next";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Docs = () => {
  const { t } = useTranslation();

  const docsData = [
    {
      name: "Over View",
    },
    {
      name: "Get started Guide",
      children: [
        {
          icon: <ChevronRightIcon />,
          name: t("guide-content-title-1", "Introduction à BotBlogR"),
        },
        {
          icon: <ChevronRightIcon />,
          name: t("guide-content-title-2", "Intégrer sa clé OpenAI"),
        },
        {
          icon: <ChevronRightIcon />,
          name: t(
            "guide-content-title-3",
            "Style d'écriture, identité et produits"
          ),
        },
        {
          icon: <ChevronRightIcon />,
          name: t("guide-content-title-4", "Outils simples"),
        },
        {
          icon: <ChevronRightIcon />,
          name: t("guide-content-title-5", "Éditeur d'articles"),
        },
      ],
    },
    {
      name: "Dummy 1",
    },
    {
      name: "Dummy 2",
    },
    {
      name: "Dummy 3",
    },
    {
      name: "Dummy 4",
    },
  ];

  const guideContent = [
    {
      step: 1,
      title: t("guide-content-title-1", "Introduction à BotBlogR"),
      subtitle: t(
        "guide-content-subtitle-1",
        "Les bases à savoir sur le logiciel, son organisation et son fonctionnement"
      ),
      text: t("guide-content-text-1", "Texte intro"),
      link: "https://www.youtube.com/watch?v=48Y9our3mig",
    },
    {
      step: 2,
      title: t("guide-content-title-2", "Intégrer sa clé OpenAI"),
      subtitle: t("guide-content-subtitle-2", "Pour commencer à générer"),
      text: t("guide-content-text-2", "Texte intégrer openai"),
      link: "https://www.youtube.com/watch?v=B",
    },
    {
      step: 3,
      title: t("guide-content-title-3", "Style d'écriture, identité et produits"),
      subtitle: t(
        "guide-content-subtitle-3",
        "Découvrez comment intégrer son propre style à tous nos outils"
      ),
      text: t("guide-content-text-3", "Texte generation variables"),
      link: "https://www.youtube.com/watch?v=C",
    },
    {
      step: 4,
      title: t("guide-content-title-4", "Outils simples"),
      subtitle: t(
        "guide-content-subtitle-4",
        "Découvrez les différents outils mis à votre disposition"
      ),
      text: t("guide-content-text-4", "Texte outils simples"),
      link: "https://www.youtube.com/watch?v=D",
    },
    {
      step: 5,
      title: t("guide-content-title-5", "Éditeur d'articles"),
      subtitle: t(
        "guide-content-subtitle-5",
        "Apprenez tout ce qu'il y a à savoir sur l'éditeur de post"
      ),
      text: t("guide-content-text-5", "Texte éditeur"),
      link: "https://www.youtube.com/watch?v=E",
    },
  ];

  const [selectedField, setSelectedField] = useState(0);
  const [openTools, setOpenTools] = useState(false);
  const [selectedChildField, setSelectedChildField] = useState(-1);
  const [selected, setSelected] = useState(docsData[0].name);

  const handleMainToolsClick = ({ item, index }) => {
    setSelectedField(index);
    if (item?.children) {
      setOpenTools(true);
    } else {
      setOpenTools(false);
      setSelectedChildField(-1);
    }
    setSelected(item.name);
  };

  const handleChildToolsClick = ({ childIndex }) => {
    setSelectedChildField(childIndex);
  };

  const renderSubMenu = (children) => {
    return (
      <>
        {children.map((child, childIndex) => (
          <div
            className={`flex  bg-white justify-center`}
            key={childIndex}
            onClick={() => handleChildToolsClick({ childIndex })}
          >
            <button
              className={`pl-6 text-[15px] ${
                selectedChildField === childIndex
                  ? "text-blue-50"
                  : "text-black"
              } w-full py-2 flex  gap-3`}
            >
              {child.icon} {child.name}
            </button>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="px-2 pl-6 pr-6">
        <div className="flex items-center justify-between py-3">
          <H1Header title={"Documents"} />
        </div>
        <div className="flex gap-2 w-full">
          <div className="bg-white min-w-[350px] shadow-default px-5 py-4 pb-5 sm:px-7.5 rounded-lg lg:h-[calc(100vh-230px)] overflow-y-auto">
            <ul className="font-medium sidbar_menu lg:block hidden">
              {docsData?.map((item, index) => {
                return (
                  <div
                    className={`${
                      selectedField === index ? "text-blue-50" : "text-black"
                    }`}
                    onClick={() => handleMainToolsClick({ item, index })}
                    key={index}
                  >
                    <p className="mb-2 text-xl font-semibold cursor-pointer">{item?.name}</p>

                    {item?.children && (
                      <ul>
                        {item?.children &&
                          openTools &&
                          renderSubMenu(item?.children)}
                      </ul>
                    )}
                  </div>
                );
              })}
            </ul>
            <div className="lg:hidden block">
              <Listbox value={selected} onChange={setSelected}>
                <div className="relative mt-2">
                  <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="flex items-center">
                      <span className="ml-3 block truncate">{selected}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      {/* <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" /> */}
                    </span>
                  </ListboxButton>

                  <ListboxOptions
                    transition
                    className="z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                  >
                    {docsData?.map((item, index) => (
                      <ListboxOption
                        key={index}
                        value={item.name}
                        onClick={() => handleMainToolsClick({ item, index })}
                        className="group relative cursor-default select-none py-2 text-gray-900"
                      >
                        <div className=" items-center">
                          <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                            {item?.name}
                          </span>

                          {item?.children && (
                            <ul>
                              {item?.children &&
                                openTools &&
                                renderSubMenu(item?.children)}
                            </ul>
                          )}
                        </div>
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
              </Listbox>
            </div>
          </div>
          <div className="bg-white px-5 pb-5 sm:px-7.5 shadow-default rounded-lg lg:h-[calc(100vh-230px)] overflow-y-auto flex-1">
          {selectedField === 1 && selectedChildField !== -1 && <UserGuideStep data={guideContent[selectedChildField]} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Docs;