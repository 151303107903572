import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Box } from '@mui/material'
import { Link } from 'react-router-dom';
import { useGetAllFavoriteFeaturesQuery } from '../../redux/slices/dashboardApiSlice';
import { useGetLastBlogPostsQuery } from '../../redux/slices/dashboardApiSlice';
import FeatureCard from '../../shared/components/features/FeatureCard'
import CustomLoader from '../../shared/components/others/CustomLoader';

const QuickAccessPanel = () => {
    const { t } = useTranslation();
    const { data: favoriteFeatures, isLoading: favoriteFeaturesLoading, refetch } = useGetAllFavoriteFeaturesQuery();
    const { data: blogPosts, isLoading: blogPostsLoading, refetch: refetchBlogPosts } = useGetLastBlogPostsQuery();

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        refetchBlogPosts();
    }, [refetchBlogPosts]);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        
        const formattedDate = date.toLocaleDateString('fr-FR');
        const formattedTime = date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
      
        return `${formattedDate} à ${formattedTime}`;
    }

    return (
        <div>
            <h2 className='text-black text-xl font-bold '>{t("favourite-tools", "Outils Favoris")}</h2>
            
            {favoriteFeaturesLoading ? (
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"65vh"}
                >
                    <CustomLoader />
                </Box>
            ) : (
                <div className='mt-2 grid md:grid-cols-2 gap-3'>
                    {Object.keys(favoriteFeatures.data).length > 0 ?
                        favoriteFeatures.data.map((feature) => {
                            return (
                                <FeatureCard
                                    key={feature.feature_id}
                                    feature={feature}
                                    isFavorite={true}
                                    refetch={refetch}
                                />
                            )
                        })
                        :
                        <p className='block font-sans text-sm antialiased font-light leading-relaxed text-inherit col-span-2'>Aucun outil ajouté aux favoris pour l'instant !</p>
                    }
                </div>
            )}

            <div className='mt-5'>
                <p className='text-black text-lg font-bold'>{t("last-posts", "Derniers articles")}</p>
            </div>

            {blogPostsLoading ? (
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"65vh"}
                >
                    <CustomLoader />
                </Box>
            ) : (
                <div className='w-full mt-2'>
                    {blogPosts?.data.length > 0 ?
                        blogPosts?.data.map((blogPost) => {
                            return (
                                <Link key={`blog-post-${blogPost.id}`} to={`${window.location.origin}/post-editor/${blogPost}`} className="w-full relative flex flex-col mt-3 no-underline text-gray-700 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 cursor-pointer  hover:border-blue-50">
                                    <div className="p-3">
                                        <h5 className="block mb-2 font-sans antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                                            {blogPost.h1_title || "Nouvel article"}
                                        </h5>
                                        {blogPost.subject && (
                                            <p className="block font-sans text-sm antialiased font-light leading-relaxed text-inherit">
                                            {blogPost.subject.length > 77 
                                                ? `${blogPost.subject.slice(0, 77)}...` 
                                                : blogPost.subject}
                                            </p>
                                        )}
                                        <div className='flex justify-end mt-2'>
                                            <div className="bg-gray-100 text-gray-700 text-[14px] text-center">
                                                {blogPost.last_update_date === blogPost.creation_date ? "Créé le " : "Dernière modification le "}{formatDate(blogPost.last_update_date)}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    :
                        <p className='block font-sans text-sm antialiased font-light leading-relaxed text-inherit col-span-2'>{t("no-last-blog-posts", "Aucun article de blog n'a été créé récemment !")}</p>
                    }
                </div>
            )}
        </div>
    )
}

export default QuickAccessPanel