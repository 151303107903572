import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ResponsiveContainer } from 'recharts';
import { useGetUsageQuery } from '../../redux/slices/dashboardApiSlice';
import CustomLoader from '../../shared/components/others/CustomLoader';
import Chart from 'react-apexcharts';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const UsagePanel = ({}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState("tokens");
    const userInfo = useSelector((state) => state?.auth.userInfo);
    const { data: userUsage, isLoading: userUsageLoading } = useGetUsageQuery();

    const nb_blog_posts = userInfo.plan === "Standard" ? 5 : (userInfo.plan === "Standard" ? 30 : 150)
    const nb_daily_tasks = userInfo.plan === "Standard" ? 1500 : (userInfo.plan === "Standard" ? 15000 : 150000)

    const tabs = [
        { name: "En tokens", value: "tokens" },
        { name: "En €", value: "cost" }
    ];

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const getOptions = () => {
        return {
            chart: {
                type: 'line',
                height: "100%",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                pan: {
                    enabled: false
                }
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'datetime',
                title: {
                    text: `Date`,
                },
                labels: {
                    datetimeUTC: true,
                },
            },
            yaxis: {
                title: {
                    text: value === "tokens" ? `Tokens` : `Coût (en €)`,
                },
                labels: {
                    formatter: (val) => `${value === "tokens" ? val : val.toFixed(2)}${value === "tokens" ? ` tokens` : `€`}`,
                },
            },
        };
    };

    const tokenUsageSeries = userUsage?.data?.token_usage
    ? userUsage.data.token_usage.map((item) => ({
        ...item,
        x: new Date(item.x).getTime(),
        y: item.y
      }))
    : [];

    const euroUsageSeries = userUsage?.data?.cost_usage
    ? userUsage.data.cost_usage.map((item) => ({
        ...item,
        x: new Date(item.x).getTime(),
        y: item.y
      }))
    : [];

    return (
        <div className='mt-3 mb-8 bg-white rounded-md px-5 py-7 pb-5 sm:px-7.5 min-h-[670px] shadow-default'>
            <div className='flex justify-between'>
                <p className='text-black text-xl font-bold'> {t("monthly-use", "Utilisation mensuelle")}</p>
            </div>

            {userUsageLoading ? (
                <div className='flex justify-center h-[65vh]'>
                    <CustomLoader />
                </div>
            ) : (
                <div className='mt-3'>

                    <p className='block font-sans text-sm antialiased font-light leading-relaxed text-inherit'>{t("nb-articles-on-period", "Nombre d'articles créés sur la période")} :
                        {userUsage.data.additional_blog_post_tokens === 0 ? (
                            <span> {userUsage.data.blog_posts_tokens}/{nb_blog_posts}</span>
                        ) : (
                            <span> {userUsage.data.additional_blog_post_tokens}+{userUsage.data.blog_posts_tokens}/{nb_blog_posts}</span>
                        )}
                    </p>

                    <p className='block font-sans text-sm antialiased font-light leading-relaxed text-inherit'>{t("nb-generated-contents-on-period", "Nombre de contenus générés sur la période")} :
                        {userUsage.data.additional_generation_tokens === 0 ? (
                            <span> {userUsage.data.generation_tokens}/{nb_daily_tasks}</span>
                        ) : (
                            <span> {userUsage?.data?.generation_tokens}+{userUsage?.data?.additional_generation_tokens}/{nb_daily_tasks}</span>
                        )}
                    </p>

                    <div className="mt-2">

                        <div className="sm:hidden">
                            <select
                                id="tabs"
                                name="tabs"
                                value={value}
                                onChange={(e) => handleChange(e.target.value)}
                                className="block w-full bg-blue-50 text-white rounded-md border-gray-300 focus:outline-none p-2"
                            >
                                {tabs.map((tab) => (
                                    <option key={tab.value} value={tab.value}>
                                    {tab.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="hidden sm:block">
                            <nav
                            aria-label="Tabs"
                            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                            >
                            {tabs.map((tab, tabIdx) => (
                                <button
                                key={tab.value}
                                onClick={() => handleChange(tab.value)}
                                className={classNames(
                                    tab.value === value
                                    ? "text-blue-50 border-b border border-blue-50 hover:bg-white"
                                    : "text-gray-500 hover:text-gray-700",
                                    tabIdx === 0 ? "rounded-l-lg" : "",
                                    tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                                    "group relative min-w-0 flex-1 overflow-hidden bg-black/10 px-4 py-4 text-center text-sm font-medium hover:bg-black/20"
                                )}
                                >
                                <span>{tab.name}</span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                    tab.value === value ? "bg-blue-50" : "bg-transparent",
                                    "absolute inset-x-0 bottom-0 h-0.5"
                                    )}
                                />
                                </button>
                            ))}
                            </nav>
                        </div>

                        <div className="mt-6">
                            {value === "tokens" && 
                                <ResponsiveContainer width="100%" height={500}>
                                    <Chart options={getOptions()} series={tokenUsageSeries} type="line" width="500px" />
                                </ResponsiveContainer>
                            }
                            {value === "cost" && 
                                <ResponsiveContainer width="100%" height={500}>
                                    <p className='text-md'>Coûts totaux sur la période : <strong>{userUsage?.data?.total_costs}€</strong></p>
                                    <Chart options={getOptions()} series={euroUsageSeries} type="line" width="500px" />
                                </ResponsiveContainer>
                            }
                        </div>

                    </div>
                </div>
            )}
        </div>
  )
}



export default UsagePanel