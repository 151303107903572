import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid, Box, TextField, Button, Modal, Typography } from "@mui/material";
import { Formik } from "formik";
import { LinkOpenAIInitialValues, LinkOpenAISchema } from "./helper";
import { ClipLoader } from "react-spinners";
import { useLinkOpenAIMutation, useUnlinkOpenAIMutation } from "../../redux/slices/profileApiSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../redux/slices/authSlice";
import { InputProfile } from "../../shared/components/profile"

const LinkOpenAI = () => {
  const { t } = useTranslation();
  const [linkOpenAI, { isLoading }] = useLinkOpenAIMutation();
  const [unlinkOpenAI, { isLoading: isUnlinking }] = useUnlinkOpenAIMutation();
  const dispatch = useDispatch();
  const { userInfo, accessToken } = useSelector((state) => state?.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleDeleteIntegration = async () => {
    try {
      const res = await unlinkOpenAI();
      if (res?.data?.status === 200) {
        const updatedUserInfo = {
          ...userInfo,
          openai_organization: null,
          openai_api_key: null,
        };
        dispatch(setCredentials(updatedUserInfo));
        toast.success("Intégration OpenAI supprimée !");
      }
    } catch (error) {
      toast.error(error?.data?.detail || "Something went wrong");
    }
  };

  const handleLinkOpenAI = async (values) => {
    try {
      const res = await linkOpenAI(values);
      if (res?.data?.status === 200) {
        const updatedUserInfo = {
          ...userInfo,
          openai_organization: res?.data?.data?.openai_organization,
          openai_api_key: res?.data?.data?.openai_api_key,
        };
        dispatch(setCredentials({
          accessToken: accessToken,
          userInfo: updatedUserInfo
        }));
        toast.success("Votre compte OpenAI est connecté !");
        setIsModalOpen(false);
      }
    } catch (error) {
      toast.error(error?.data?.detail || "Something went wrong");
    }
  };

  return (
    <>
      <Formik
        initialValues={
          userInfo?.openai_api_key || userInfo?.openai_organization
            ? userInfo
            : LinkOpenAIInitialValues
        }
        enableReinitialize={true}
        validationSchema={LinkOpenAISchema}
        onSubmit={handleLinkOpenAI}
      >
        {(props) => (
          <div className="mx-auto h-[70vh] flex items-center justify-center bg-white shadow-default rounded-2xl">
            <form onSubmit={props.handleSubmit} className="w-[630px]">
              
              <div className="grid md:grid-cols-2 gap-x-11 gap-5 w-full">
                
                <div className="col-span-2">
                  <InputProfile
                    formikProps={props}
                    type="text"
                    text={t("openai-organization", "Organisation OpenAI")}
                    name="openai_organization"
                    placeholder={t("openai-organization-format", "org-...")}
                    disabled={userInfo?.openai_organization ? true : false}
                  />
                </div>

                <div className="col-span-2">
                  <InputProfile
                    formikProps={props}
                    type="text"
                    text={t("openai-api-key", "Clé API OpenAI")}
                    name="openai_api_key"
                    placeholder={t("openai-api-key-format", "sk-...")}
                    disabled={userInfo?.openai_api_key ? true : false}
                  />
                </div>

                <div className="col-span-2">
                  {userInfo?.openai_api_key && userInfo?.openai_organization ? (
                    <div>
                      <button 
                        type="button"
                        className="px-5 w-full py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light"
                        onClick={() => setIsModalOpen(true)}
                      >
                        {isLoading ? (
                          <ClipLoader size={25} color="white" />
                        ) : (
                          "Modifier l'intégration"
                        )}
                      </button>

                      <button 
                        type="button"
                        className="px-5 w-full py-[6px] mt-4 bg-error text-white rounded font-light"
                        onClick={() => handleDeleteIntegration()}
                      >
                        {isLoading ? (
                          <ClipLoader size={25} color="white" />
                        ) : (
                          "Supprimer l'intégration"
                        )}
                      </button>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="px-5 w-full py-[6px] hover:bg-blue-200 *: bg-blue-100 text-white rounded font-light"
                    >
                      {isLoading ? (
                        <ClipLoader size={25} color="white" />
                      ) : (
                        "Lier mon compte OpenAI"
                      )}
                    </button>
                  )}
                </div>

              </div>

            </form>
          </div>
          

        )}
      </Formik>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container sx={style}>
          <Typography id="modal-modal-title" variant="h3" fontSize={28}>
            Entrez vos nouvelles informations OpenAI
          </Typography>
          <Formik
            initialValues={LinkOpenAIInitialValues}
            validationSchema={LinkOpenAISchema}
            onSubmit={handleLinkOpenAI}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box>
                  <Grid item lg={12} md={12} sm={12} xs={12} pt={5}>
                    <TextField
                      placeholder="org-..."
                      label="Organisation OpenAI"
                      variant="outlined"
                      value={props.values.openai_organization}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      name="openai_organization"
                      error={
                        props.touched.openai_organization &&
                        Boolean(props.errors.openai_organization)
                      }
                      required
                      sx={{
                        width: "95% !important",
                        borderRadius: "20px !important",
                      }}
                    />
                  </Grid>
                </Box>

                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <Box pt={2}>
                    <TextField
                      placeholder="sk-..."
                      label="Clé API OpenAI"
                      variant="outlined"
                      value={props.values.openai_api_key}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      name="openai_api_key"
                      error={
                        props.touched.openai_api_key &&
                        Boolean(props.errors.openai_api_key)
                      }
                      required
                      sx={{ width: "95% !important" }}
                    />
                  </Box>
                </Grid>

                <Box pt={2}>
                  <Button
                    variant="contained"
                    onClick={props.handleSubmit}
                    disabled={isLoading}
                    sx={{
                      width: "95% !important",
                      textTransform: "capitalize",
                    }}
                  >
                    {isLoading ? (
                      <ClipLoader size={25} color="white" />
                    ) : (
                      "Lier mon compte OpenAI"
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Modal>
    </>
  );
};

export default LinkOpenAI;