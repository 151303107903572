import React from "react";
import pen from "../../../assets/images/pen.svg";
import { GVModalTemplate } from "./index";

const WritingModal = ({ 
  open, 
  handleClose,
  formikProps,
  writingStyles
}) => {

  return (
    <GVModalTemplate
      open={open}
      handleClose={handleClose}
      formikProps={formikProps}
      generationVariablesList={writingStyles}
      fieldName="style_id"
      modalTitle="Style d'écriture"
      modalIcon={pen}
      modalDescription="Customize the writing of your article to align seamlessly with the writing style of your website and the preferences of your readers."
      noSelectionText="Aucun style sélectionné"
    />
  );
};

export default WritingModal;
